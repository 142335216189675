<template>
  <div :class="$style['payments-wrapper']">
    <div :class="$style['title']">{{ t("page_footer.payment_systems") }}</div>
    <div :class="$style['payments']">
      <div :class="$style['mastercard']">
        <img
          src="@/assets/payments/mastercard.png"
          alt="mastercard"
        />
      </div>
      <div :class="$style['visa']">
        <img
          src="@/assets/payments/visa.png"
          alt="visa"
        />
      </div>
      <div :class="$style['paykeeper']">
        <img
          src="@/assets/payments/paykeeper.png"
          alt="paykeeper"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
const { t } = useI18n();
</script>

<style module lang="scss">
.payments-wrapper {
  margin-top: var(--spacing-6xl);
  display: flex;
  flex-direction: column;
  gap: var(--spacing-2lg);

  .title {
    color: var(--general-transparent-light-50);
    font-size: 1rem;
    font-weight: 500;
    line-height: 135%;
    letter-spacing: -0.032px;

    @media screen and (max-width: $md) {
      font-size: 14px;
      letter-spacing: -0.014px;
    }

    @media screen and (max-width: $sm) {
      font-size: 12px;
    }
  }

  .payments {
    display: flex;
    width: 100%;
    align-items: center;
    gap: var(--spacing-2lg);
    padding-bottom: 30px;

    img {
      height: 100%;
    }

    .mastercard {
      height: 40px;
    }

    .visa {
      height: 27px;
    }

    .paykeeper {
      height: 34px;
    }

    @media screen and (max-width: $md) {
      gap: var(--spacing-xl);
      padding: 0;
    }
  }
}
</style>
