<template>
  <SimpleDropdown
    :show-values="showSearchResults"
    :align="'left'"
    :dropdown-classes="[
      $style.searcher,
      isActive ? $style['active-searcher'] : '',
    ]"
    :values-classes="[$style['searcher-results']]"
  >
    <template #caller>
      <Input
        v-model.trim="keyWords"
        ref="inputComponent"
        :placeholder="t('home.searcher')"
        size="m"
        :class="$style['searcher-input']"
        data-test="header-games-searcher"
        @on-focus="setActive"
      >
        <template #leftIcon>
          <IconMagnifier :class="$style.magnifier" />
        </template>
      </Input>
      <PopupButton
        v-if="isActive"
        :class="$style['close-btn']"
        @click="setInactive"
      >
        <IconCross />
      </PopupButton>
    </template>
    <template #values>
      <template v-if="searchResults.length">
        <ActionCard
          v-for="item in searchResults"
          :key="item.id"
          :img="item.logo || ''"
          :action="item.title"
          :with-arrow="true"
          :class="$style['searcher-action-card']"
          size="xs"
          data-test="search-result"
          @click="handleActionCardClick(item.id)"
        />
        <PrimaryButton
          v-if="showLoadBtn"
          :class="$style['show-more-btn']"
          :loading="showLoadBtnSpinner"
          @click="handleLoadBtnClick"
        >
          {{ t("show_more") }}
        </PrimaryButton>
      </template>
      <div
        v-else
        :class="$style['no-results']"
        data-test="no-results"
      >
        {{ t("notifications.request.not_found") }}
      </div>
    </template>
  </SimpleDropdown>
  <transition name="searcher-overlay">
    <div
      v-if="showOverlay"
      :class="$style['searcher-overlay']"
      data-test="searcher-overlay"
      @click.self="setInactive"
    />
  </transition>
  <SecondaryButton
    v-show="!isActive"
    :class="$style['searcher-btn']"
    data-test="mobile-header-games-searcher"
    @click="setActive"
  >
    <IconMagnifier :class="$style.magnifier" />
  </SecondaryButton>
</template>

<script setup lang="ts">
import SimpleDropdown from "~/components/Shared/Dropdown/SimpleDropdown.vue";
import Input from "~/components/Shared/Input/Input.vue";
import ActionCard from "~/components/Shared/ActionCard/index.vue";
import PopupButton from "~/components/Shared/Button/PopupButton.vue";
import PrimaryButton from "~/components/Shared/Button/PrimaryButton.vue";
import SecondaryButton from "~/components/Shared/Button/SecondaryButton.vue";
import type { ComponentPublicInstance } from "vue";
import type { Game } from "~/api_gen";

let timer: ReturnType<typeof setTimeout>;
let offset: number | undefined = undefined;
const api = useApi();
const { t } = useI18n();
const limit = 10;
const showLoadBtnSpinner = ref(false);
const blocked = ref(false);
const isActive = ref(false);
const keyWords = ref("");
const showSearchResults = ref(false);
const showOverlay = ref(false);
const searchResults = ref<Game[]>([]);
const showLoadBtn = ref(false);
const inputComponent = ref<ComponentPublicInstance | null>(null);
const emit = defineEmits(["active", "inactive"]);

watch(keyWords, async (value: string) => {
  if (value) {
    handleKeyWordsChange();
  } else {
    showSearchResults.value = false;
    searchResults.value = [];
  }
});

async function handleKeyWordsChange() {
  clearTimeout(timer);
  offset = undefined;
  timer = setTimeout(getSearchResults, 500);
}

async function getSearchResults(concat: boolean = false) {
  blocked.value = true;
  try {
    const requestParameters = {
      search: keyWords.value,
      limit,
      offset,
    };
    const response = await api.games.list(requestParameters);
    if (concat) {
      searchResults.value = searchResults.value.concat(response.results);
    } else {
      searchResults.value = response.results;
    }
    showSearchResults.value = true;
    if (response.next) {
      const offsetParam = new URL(response.next).searchParams.get("offset");
      offset = offsetParam ? parseInt(offsetParam) : undefined;
      showLoadBtn.value = true;
    } else {
      offset = undefined;
      showLoadBtn.value = false;
    }
  } catch (error) {
    showSearchResults.value = false;
  } finally {
    blocked.value = false;
  }
}

async function handleLoadBtnClick() {
  showLoadBtnSpinner.value = true;
  await getSearchResults(true);
  showLoadBtnSpinner.value = false;
}

async function handleActionCardClick(id: number) {
  setInactive();
  await nextTick();
  navigateTo(`/products/${id}`);
}

async function setActive() {
  isActive.value = true;
  showOverlay.value = true;
  document.documentElement.style.overflow = "hidden";
  if (keyWords.value) showSearchResults.value = true;
  if (inputComponent.value) {
    await nextTick();
    inputComponent.value?.$el.querySelector("input")?.focus();
  }
  emit("active");
}

function setInactive() {
  isActive.value = false;
  showSearchResults.value = false;
  showOverlay.value = false;
  document.documentElement.style.overflow = "";
  emit("inactive");
}

onBeforeUnmount(() => clearTimeout(timer));
</script>

<style lang="scss">
.searcher-overlay-enter-from,
.searcher-overlay-leave-to {
  opacity: 0;
}
.searcher-overlay-enter-active,
.searcher-overlay-leave-active {
  transition: 0.15s;
}
</style>

<style lang="scss" module>
.searcher,
.searcher-btn {
  margin-left: var(--spacing-5xl);
  @media screen and (max-width: 450px) {
    margin-left: var(--spacing-2xl);
  }
}
.searcher-input,
.searcher-results {
  width: 100% !important;
}
.searcher-results,
.searcher-action-card {
  background: var(--general-underlay-1-lvl) !important;
}
.searcher-btn,
.close-btn {
  flex-shrink: 0;
}
.searcher {
  position: relative;
  z-index: 102;
  width: 359px;
  @media screen and (max-width: $md) {
    display: none;
  }
}
.active-searcher {
  display: block;
  @media screen and (max-width: $md) {
    width: 100%;
    margin-left: 0;
  }
}
.searcher-input-container {
  position: relative;
}
.searcher-input {
  padding-left: var(--spacing-5xl) !important;
  .magnifier {
    left: 20px;
    transform: translateY(-50%);
  }
}
.magnifier {
  position: absolute;
  top: 50%;
  width: 16px;
}
.searcher-results {
  top: calc(100% + 8px);
  // ниже 90px и 70px это высота PageHeader на моб. устройствах
  & > div {
    max-height: calc(75vh - 90px) !important;
    @media screen and (max-width: $md) {
      max-height: calc(85vh - 70px) !important;
    }
  }
}
.searcher-action-card {
  &:hover {
    background: var(--cells-hover) !important;
  }
}
.searcher-overlay {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 101;
  width: 100%;
  height: 100dvh;
  background: var(--general-transparent-dark-85);
}
.close-btn {
  display: none !important;
  margin-left: var(--spacing-md);
  @media screen and (max-width: $md) {
    display: block !important;
  }
  svg {
    width: 15px;
  }
}
.show-more-btn {
  display: block;
  width: 254px;
  margin: auto;
  margin-top: var(--spacing-md);
  @media screen and (max-width: 450px) {
    width: 100%;
  }
}
.searcher-btn {
  width: 44px;
  height: 44px;
  @media screen and (min-width: calc($md + 1px)) {
    display: none !important;
  }
  .magnifier {
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
.no-results {
  padding: var(--spacing-5xl) 0;
  font-size: 0.875rem;
  text-align: center;
  color: var(--general-grey-light);
}
</style>
