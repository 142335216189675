<template>
  <SharedInputField
    v-if="!codeInfoRequired"
    :error="error"
  >
    <SharedInput
      v-model="code"
      ref="inputRef"
      size="l"
      :placeholder="t('codes.insert_code')"
      @onFocus="handleInputFocus"
      @onBlur="handleInputBlur"
    >
      <template #leftIcon>
        <IconPromoCode width="18" />
      </template>
      <template #rightIcon>
        <div :class="$style['spinner-wrapper']">
          <LoadingSpinner
            v-if="codeStore.isCodeChecking"
            width="18"
          />
        </div>
      </template>
    </SharedInput>
  </SharedInputField>
  <div
    v-else
    ref="codeInfoRef"
    :class="$style['code-data']"
    data-test="popup-promo-descr"
    @click="handleCodeDataClick"
  >
    <IconPromoCode width="18" />
    {{ description }}
    <IconCheck width="18" />
  </div>
</template>

<script setup lang="ts">
import LoadingSpinner from "~/components/Shared/Spinners/LoadingSpinner.vue";
import type { PromoCode } from "~/api_gen";

const code = defineModel<string>();
const props = defineProps<{
  error?: string;
  currentCodeData?: PromoCode;
  inactive?: boolean;
}>();
const { error, inactive, currentCodeData } = toRefs(props);
const codeStore = useCodesStore();
const inputFocused = ref<boolean>();
const inputRef = ref<HTMLElement>();
const codeInfoRef = ref<HTMLElement>();

const codeInfoRequired = computed(
  () =>
    inactive.value ||
    (!error.value && !inputFocused.value && currentCodeData.value),
);

const handleInputFocus = () => {
  inputFocused.value = true;
};
const handleInputBlur = () => {
  inputFocused.value = false;
};
const handleCodeDataClick = () => {
  inputFocused.value = true;
  nextTick(() => {
    if (inputRef.value) {
      inputRef.value.focus();
    }
  });
};
watch(code, () => {
  if (code.value) {
    codeStore.checkCode(code.value);
  }
});
watch(currentCodeData, () => {
  if (currentCodeData.value) {
    inputFocused.value = false;
  }
});
const { t } = useI18n();
const description = computed(() =>
  t("codes.info", {
    code: currentCodeData.value?.code,
    percent: toLocalNumber(Number(currentCodeData.value?.percent)),
  }),
);
</script>

<style module>
.code-data {
  display: flex;
  height: 54px;
  padding: var(--spacing-xs) var(--spacing-2xl);
  align-items: center;
  gap: var(--spacing-md);
  border-radius: var(--radius-max);
  background: var(--input-default);
  color: var(--optional-mint);
  justify-content: space-between;
  cursor: pointer;
}
.spinner-wrapper {
  position: relative;
  width: 18px;
}
</style>
